import React from "react";
import "./index.css";

const mainBg = require("./img/bg.png");
const paperBg = require("./img/paper.png");
const starsBg = require("./img/stars.svg").default;

function WhiteListComponent() {
  return (
    <div className="white-list" style={{ backgroundImage: `url(${mainBg})`, height: '100%', width: '100%', flexDirection: 'column' }}>
        <div className="white-list-top-img" style={{
          width: "100%",
          height: "200px",
          backgroundImage: `url(${starsBg})`,
          backgroundSize: "cover",
          backgroundRepeat: 'no-repeat'
        }}>
        
        </div>
        <h1 className="white-list-text">W-Coin Whitepaper</h1>
        <img className="paper-img" src={paperBg} alt="Paper" />
    </div>
  );
}

export const WhiteList = React.memo(WhiteListComponent);
